// GENERAL REACT MODULES
import React from "react";
import { Container, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";

//PAGE SPECIFIC MODULES
import GrafanaFrame from "components/GrafanaFrame/GrafanaFrame.view.jsx";
import _basePanelView from "components/General/_basePanelView.jsx"
import Controller from "./Assets.controller.jsx"
import CotaUserBar from "components/CotaUserBar/CotaUserBar.partial.jsx"
import config_data from "./AssetSearch.json"
import "./Assets.css"
import ViewAssetsPanel from "./ViewAssetsPanel/ViewAssetsPanel.view";
import EditTruckPanel from "./EditTruck/EditTruckPanel.view.jsx";
import EditTrailerPanel from "./EditTrailer/EditTrailerPanel.view.jsx";
import grafana_config from "../../controllers/GrafanaConfig/GrafanaConfig.js";
import CotaListPanel from "components/CotaListPanel/CotaListPanel.partial.jsx"
import panel_config_data from "./assets.json"
import ViewDriver from "../Driver/ViewDriver/ViewDriver.view.jsx"

export default class Assets extends _basePanelView {
    constructor(props) {
        super(props)
        this.controller = new Controller({ params: { view: this } })
        this.state.trailer = false
        this.state.truck = false
        this.state.asset_type = ""
        this.state.status_options = []
        this.state.assets = []
        this.state.panel_name = "assets"
        this.state.channel_name = "grafana_row"
        this.state.panel_configs = grafana_config()["pages"]["assets"]
        this.state.user_options = [{ value: "", label: "All" }]
        this.state.config_data = config_data
        this.state.form = config_data.form_objects
        this.state.panel_trees = [{ key: 0, name: "view_assets", base: 0, paths: [{"view_driver": 3}]},
                                    { key: 1, name: "edit_truck", base: 1 },
                                    { key: 2, name: "edit_trailer", base: 2 }]
        this.state.panels = [{ key: 0, obj: ViewAssetsPanel, params: { panel_params: { is_closeable: false, close_title: "Back Home", close_func: "resetScreen", controller: this.controller } } },
                                { key: 1, obj: EditTruckPanel, params: { panel_params: { is_closeable: true, close_title: "Back Home", close_func: "resetScreen", controller: this.controller } } },
                                { key: 2, obj: EditTrailerPanel, params: { panel_params: { is_closeable: true, close_title: "Back Home", close_func: "resetScreen", controller: this.controller } } },
                                { key: 3, obj: ViewDriver, params: { panel_params: { is_closeable: true, close_title: "Back Home", close_func: "resetScreen", controller: this.controller } } }]
        this.handleProcessEvent = this.handleProcessEvent.bind(this)
    };
    followDidMount() {
        this.mount_listener()
    }
    reverse_type(text) {
        // if both are true, dont
        if (this.state.trailer && this.state.truck) {
            return text
        }
        // otherwise, reverse
        if (text === "TRAILER") { return "TRUCK" }
        if (text === "TRUCK") { return "TRAILER" }
    }
    handle_click(event) {
        let text = event.target.textContent.toUpperCase()
        this.setState(prevState => ({
            asset_type: prevState?.asset_type === "" ? this.reverse_type(text) : "",
            [text.toLowerCase()]: !prevState?.[text.toLowerCase()]
        }))
    }
    // renderGrafanaFrame() {
    //     if (this.state?.company_id === undefined) {return <></>}
    //     return (
    //         <GrafanaFrame params={{ parent: this, 
    //                                 panel_configs: this.state.panel_configs, 
    //                                 panel_name: this.state.panel_name,
    //                                 filters: {
    //                                     asset_type: JSON.stringify(this.state?.asset_type),
    //                                     statuses: JSON.stringify(this.state?.page?.status_filter),
    //                                     // nots: ((this.state?.page?.status_filter?.length > 0) ? "" : "status_id"),
    //                                     asset_number: this.state?.assets_search?.search_assets?.search_value,
    //                                     company_id: this.state?.company_id,
    //                                 }}}

    //         />
    //     )
    // }

    renderMain() {
        return (
            <>
                <Container id={this.name} className="generic_100 g-0">
                    <Row className="max_height text_color">
                        <Col className="flex_wrapper_rows">
                            <Row>
                                <Col className="flex_wrapper_rows">
                                    <CotaUserBar params={{ parent: this, controller: this.controller, title: "Assets" }} />
                                </Col>
                            </Row>
                            <Row className="generic_100 g-0 cota_panel_object add_shadow ">
                                <Col className="generic_100">
                                    <Row className="toolbar">
                                        <Col sm={1} >
                                            {/* <div className={`${this.state.trailer ? "btn_role" : "btn_trailer"}`} onClick={() => { this.setState({asset_trailer: this.state.asset_trailer = "TRUCK", trailer: !this.state.trailer })}}> */}
                               
                                        </Col>
                                        <Col sm={1} className="badge_col">
                                            {/* <div className={`${this.state.trailer ? "btn_role" : "btn_trailer"}`} onClick={() => { this.setState({asset_trailer: this.state.asset_trailer = "TRUCK", trailer: !this.state.trailer })}}> */}
                                            <div className={`${this.state.trailer ? "btn_role" : "btn_trailer"}`} onClick={this.handle_click.bind(this)}>
                                                TRAILER
                                            </div>
                                        </Col>
                                        <Col sm={1} >
                                            {/* <div className={`${this.state.trailer ? "btn_role" : "btn_trailer"}`} onClick={() => { this.setState({asset_trailer: this.state.asset_trailer = "TRUCK", trailer: !this.state.trailer })}}> */}
                               
                                        </Col>
                                        <Col sm={1} className="badge_col">
                                            {/* <div className={`${this.state.truck ? "btn_role" : "btn_truck"}`} onClick={() => { this.setState({ asset_truck: this.state.asset_truck = "TRAILER", truck: !this.state.truck })}}> */}
                                            <div className={`${this.state.truck ? "btn_role" : "btn_truck"}`} onClick={this.handle_click.bind(this)}>
                                                TRUCK
                                            </div>
                                        </Col>
                                        <Col sm={1} >
                                            {/* <div className={`${this.state.trailer ? "btn_role" : "btn_trailer"}`} onClick={() => { this.setState({asset_trailer: this.state.asset_trailer = "TRUCK", trailer: !this.state.trailer })}}> */}
                               
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    {this.formObject({ data: this.state.form.status_filter, options: this.state.status_options, owner: this})}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className="margin_auto">
                                                    {this.formObject({
                                                        data: this.state.form.assets_search,
                                                        options: this.state.user_options,
                                                        owner: this,
                                                        controller: this.controller
                                                    })}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={2}>
                                            <Row>
                                                <Col>
                                                    <style type="text/css">
                                                        {`
                                                        .btn-flat {
                                                            background-color: #043B64;
                                                            color: white;
                                                        }
                                                        .btn-flat:hover {
                                                            background-color: #0d6efd;
                                                            color: white;
                                                        }
                                                    
                                                        .btn-xxl {
                                                            width: 150px
                                                        }
                                                        `}
                                                    </style>
                                                    <DropdownButton variant="flat" size="xxl" title="New Asset" className="item_width">
                                                        <Dropdown.Item onClick={this.handleScreen.bind(this, { path_num: 1, screen_num: 1 })} >
                                                            Create Truck
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={this.handleScreen.bind(this, { path_num: 2, screen_num: 2 })} >
                                                            Create Trailer
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col>
                                            <CotaListPanel params={{
                                                parent: this,
                                                configs: panel_config_data?.panels,
                                                panel_configs: this.state.panel_configs,
                                                panel_name: this.state.panel_name,
                                                query: "assets_by_page",
                                                endpoint: "assets",
                                                // args: this.state?.asset_type === "" ? `statuses:["${this.state?.page?.status_filter[0] === undefined || this.state?.page?.status_filter === ""  ? "IDLE\",\"HOOKED\",\"AVAILABLE\",\"ASSIGNED\",\"DISPATCHED\",\"OUT_OF_SERVICE" : this.state?.page?.status_filter }"]` : `statuses:["${this.state?.page?.status_filter === "" || this?.state?.page?.status_filter[0] === undefined ? "IDLE\",\"HOOKED\",\"AVAILABLE\",\"ASSIGNED\",\"DISPATCHED\",\"OUT_OF_SERVICE" : this?.state?.page?.status_filter}"], asset_type:"${this?.state?.asset_type}"` ,
                                                // req_name: "general",
                                                // configs:{clickable:true},
                                                is_paginated: true,
                                                filters: {
                                                    asset_type: this.state?.asset_type,
                                                    statuses: this.state?.page?.status_filter,
                                                    // nots: ((this.state?.page?.status_filter?.length > 0) ? "" : "status_id"),
                                                    asset_number: this.state?.assets_search?.search_assets?.search_value,
                                                    // company_id: this.state?.company_id,
                                                }
                                            }} />
                                            {/*                             <CotaListPanel params={{parent: this, 
                                                        panel_name: this.state.panel_name,
                                                        configs: config_data?.panels,
                                                        endpoint: "contacts",
                                                        filters: {name: this.controller.user_filter, time: this.controller.process_date(this.view?.state?.page?.time_frame)}}}/> */}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }



}